<!-- 用户登录界面 -->
<template>
  <div id="login">
    <div class="bg"></div>
    <el-row class="main-container">
      <el-col :lg="8" :xs="16" :md="10" :span="10">
        <div class="top">
          <i class="iconfont icon-kaoshi"></i><span class="title">在线考试系统</span>
        </div>
        <div class="bottom">
          <div class="container">
            <p class="title">账号登录</p>
            <el-form label-width="80px" :model="formLabelAlign" :rules="rules">
              <el-form-item label="用户名">
                <el-input v-model="formLabelAlign.email" placeholder="请输入用户名"></el-input>
              </el-form-item>
              <el-form-item label="密码">
                <el-input v-model="formLabelAlign.password" placeholder="请输入密码" type='password'></el-input>
              </el-form-item>
              <el-form-item prop="code" v-if="captchaEnabled">
                <div style="display: flex; align-items: center;">
                  <el-input
                      v-model="formLabelAlign.code"
                      size="large"
                      auto-complete="off"
                      placeholder="验证码"
                      style="width: 50%; margin-right: 10px;"
                  >
                  </el-input>
                  <div class="login-code">
                    <img :src="codeUrl" @click="getCode" class="login-code-img" style="cursor: pointer;"/>
                  </div>
                </div>
              </el-form-item>

              <div class="submit">
                <el-button type="primary" class="row-login" @click="login()">登录</el-button>
              </div>
            <div class="options">
                <p class="find"><a href="javascript:;" @click="findPassWord()">找回密码</a></p>
                <div class="register">
                  <span>没有账号?</span>
                  <span><a href="javascript:;" @click="register">去注册</a></span>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="footer">
      <el-col>
        <p class="msg2"> <a target="_blank">LCH</a></p>
      </el-col>
    </el-row>
    <section class="remind">
      <span>管理员账号：admin@qq.com</span>
      <span>管理员密码：admin</span>
    </section>
  </div>
</template>

<script>
import store from '../../router/index'
import {setToken} from "@/utils/token";
export default {
  store,
  name: "login",
  data() {
    return {
      role: 2,
      formLabelAlign: {
        email: '',
        password: '',
        uuid:'', //  用来记录用户的唯一标识
        code:'', // 验证码
      },
      codeUrl:'', // 生成图片的地址
      captchaEnabled: true,
      // 表格验证定义
      rules:{
        email:[
          {required: true, message: '账号不能为空', trigger: 'blur'},
        ],
        code:[
          {required: true, message: '验证码不能为空', trigger: 'blur'},
        ],
        password:[
          {required: true, message: '密码不能为空', trigger: 'blur'},
        ]
    }

    }
  },
  methods: {
    //用户登录请求后台处理
    login() {
      console.log("登录操作执行-------");
      this.$axios({
        url:'/user/login',
        method: 'post',
        data: {
            ... this.formLabelAlign
        }
      }).then(res=>{
        console.log(res) ;
        let resData = res.data
        var message = res.message
        console.log(resData)
        if(resData != null) {
          this.$cookies.set("token",res.data.token) ;
          this.$cookies.set("user",res.data.user) ;
          setToken(res.data.token)
          // 默认跳转到答题者界面
          this.$router.push({path: '/student'})
        }
        if(resData == null) { //错误提示
          this.$message({
            showClose: true,
            type: 'error',
            message: message
          })
          // 重新生成验证码
          this.getCode();
        }
      })
    },
    clickTag(key) {
      this.role = key
    },
    register(){
      this.$router.push({path:'/register'})
    },
    findPassWord(){
      this.$router.push({path:'/forget'})
    },
    // 实现验证码的生成
    getCode(){
      console.log("验证码生成-------");
      this.$axios({
        url:'/user/captchaImage',
        method: 'get'
      }).then(res=>{
          console.log(res)
        this.codeUrl = "data:image/gif;base64," + res.data.img
        this.formLabelAlign.uuid = res.data.uuid
      })
    }
  },
  // 钩子函数-- 组件挂载后执行
  mounted(){
    this.getCode() ;
  }
}
</script>


<style lang="scss" scoped>
.remind {
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 50%;
  flex-direction: column;
  color: #606266;
  background-color: #fff;
  border-left: 4px solid #409eff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}
.container {
  margin-bottom: 32px;
}
.container .el-radio-group {
  margin: 30px 0px;
}
a:link {
  color:#ff962a;
  text-decoration:none;
}
#login {
  font-size: 14px;
  color: #000;
  background-color: #fff;
}
#login .bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  background: url('../../assets/img/loginbg.png')center top / cover no-repeat;
  background-color: #b6bccdd1 !important;
}
#login .main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#login .main-container .top {
  margin-top: 100px;
  font-size: 30px;
  color: #ff962a;
  display: flex;
  justify-content: center;
}
#login .top .icon-kaoshi {
  font-size: 80px;
}
#login .top .title {
  margin-top: 20px;
}
#login .bottom {
  display:flex;
  justify-content: center;
  background-color:#fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#login .bottom .title {
  text-align: center;
  font-size: 30px;
}
.bottom .container .title {
  margin: 30px 0px;;
}
.bottom .submit .row-login {
  width: 100%;
  background-color: #04468b;
  border-color: #04468b;
  margin: 20px 0px 10px 0px;
  padding: 15px 20px;
}
.bottom .submit {
  display: flex;
  justify-content: center;
}
.footer {
  margin-top: 50px;
  text-align: center;
}
.footer .msg1 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
}
.footer .msg2 {
  font-size: 14px;
  color: #e3e3e3;
  margin-top: 70px;
}
.bottom .options {
  margin-bottom: 40px;
  color: #ff962a;
  display: flex;
  justify-content: space-between;
}
.bottom .options > a {
  color: #ff962a;
}
.bottom .options .register span:nth-child(1) {
  color: #8C8C8C;
}
</style>
